// Import everything from model under name model
import * as model from './model.js';
import { MODAL_CLOSE_SEC } from './config.js';
import recipeView from './views/recipeView.js';
import searchView from './views/searchView.js';
import resultsView from './views/resultsView.js';
import paginationView from './views/paginationView.js';
import bookmarksView from './views/bookmarksView.js';
import addRecipeView from './views/addRecipeView.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { async } from 'regenerator-runtime';

//activate parcel hot refresh
// if (module.hot) {
//   module.hot.accept();
// }

// Is called by an event listener from publisher
const controlRecipes = async function () {
  try {
    const id = window.location.hash.slice(1);
    if (!id) return;

    recipeView.renderSpinner();

    // 0. Update results view to mark selected search result, without reloading the images.
    resultsView.update(model.getSearchResultsPage());

    // 1. Updating bookmarks view
    bookmarksView.update(model.state.bookmarks);

    // 2. Getting, loading recipe, will return a promise, so await
    await model.loadRecipe(id);

    // 3. Rendering recipe in recipeView.js
    recipeView.render(model.state.recipe);
  } catch (err) {
    recipeVieww.renderError();
    console.error(err);
  }
};

// Control the search
const controlSearchResults = async function () {
  try {
    resultsView.renderSpinner();
    // 1. Get search query
    const query = searchView.getQuery();
    if (!query) return;

    // 2. Load search results
    await model.loadSearchResults(query);

    // 3. Render results in resultsView.js
    // resultsView.render(model.state.search.results); // all results
    resultsView.render(model.getSearchResultsPage());

    // 4. Render initial pagination btns
    paginationView.render(model.state.search);
  } catch (err) {
    console.error(err);
  }
};

// Buttons controler
const controlPagination = function (goToPage) {
  console.log(goToPage);

  // 1. Render new results in resultsView.js
  // resultsView.render(model.state.search.results); // all results
  resultsView.render(model.getSearchResultsPage(goToPage));

  // 2. Render new pagination buttons
  paginationView.render(model.state.search);
};

const controlServings = function (newServings) {
  // Update the recipe servings (in state) so in the model
  model.updateServings(newServings);

  // Update the recipe view
  // recipeView.render(model.state.recipe);
  recipeView.update(model.state.recipe);
};

// Will trigger whenever click the bookmark button, toogle button
const controlAddBookmark = function () {
  // we want to bookmark when the recipe is not already bookmarked
  if (!model.state.recipe.bookmarked) model.addBookmark(model.state.recipe);
  // we want to delete the bookmark if is already bookmarked
  else model.deleteBookmark(model.state.recipe.id);

  console.log(model.state.recipe);
  // Update the changes (bookmark icon)
  recipeView.update(model.state.recipe);

  // Render bookmarks
  bookmarksView.render(model.state.bookmarks);
};

// Render the bookmarks in bookmarksView BEFORE the update fn
const controlBookmarks = function () {
  bookmarksView.render(model.state.bookmarks);
};

// Recive the new data
const controlAddRecipe = async function (newRecipe) {
  try {
    // console.log(newRecipe);
    // Show the spinner
    addRecipeView.renderSpinner();

    // the uploadRecipe returns a promise, if rejected will get caught
    await model.uploadRecipe(newRecipe);
    console.log(model.state.recipe);

    // Render new recipe
    recipeView.render(model.state.recipe);

    // Display a success message in modal
    addRecipeView.renderMessage();

    // Render the bookmark view, insert a new element not update
    bookmarksView.render(model.state.bookmarks);

    // Change the #id in url, using History API without reloading the page, (state=null, title='', url)
    window.history.pushState(null, '', `#${model.state.recipe.id}`);
    window.history.forward;
    // Close the modal window after some time
    setTimeout(function () {
      addRecipeView.toggleWindow();
    }, MODAL_CLOSE_SEC * 1000);
  } catch (err) {
    console.error('*!*', err);
    // will take the message that we throw it and display it in modal
    addRecipeView.renderError(err.message);
  }
};

// const newFeature = function () {
//   console.log('Welcome to application!');
// };

// Passing the subscriber(controller) to the publisher(view), here are handled the events, will pass the handler(a control function, which gets the data from model and render view) to the view listener.
const init = function () {
  bookmarksView.addHandlerRender(controlBookmarks);
  recipeView.addHandlerRender(controlRecipes);
  recipeView.addHandlerUpdateServings(controlServings);
  recipeView.addHandlerAddBookmark(controlAddBookmark);
  searchView.addHandlerSearch(controlSearchResults);
  paginationView.addHandlerClick(controlPagination);
  addRecipeView.addHandlerUpload(controlAddRecipe);
  // newFeature();
};
init();
