import { TIMEOUT_SEC } from './config.js';

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} seconds`));
    }, s * 1000);
  });
};

// Refactor as AJAX both getJSON and sendJSON -are very similar
export const AJAX = async function (url, uploadData = undefined) {
  try {
    // Conditionaly defined fetchPro variable,
    const fetchPro = uploadData //if uploadData exist then use options
      ? fetch(url, {
          //passing an object of options to fetch() for POST
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', //telling API -data is json
          },
          body: JSON.stringify(uploadData), //payload
        })
      : fetch(url); //else use just url to get

    // Use a timeout to reject the fetch - not hanging out
    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message}, (${res.status})`);
    return data;
  } catch (err) {
    // Propagate the error forward to be catch, and reject the promise
    throw err;
  }
};

/*
// fetch automatically get data if only url is present
export const getJSON = async function (url) {
  try {
    // Use a timeout to reject the fetch - not hanging out
    const res = await Promise.race([fetch(url), timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message}, (${res.status})`);
    return data;
  } catch (err) {
    // Propagate the error forward to be catch, and reject the promise
    throw err;
  }
};

// fetch need an obj of options to POST data
export const sendJSON = async function (url, uploadData) {
  try {
    //passing an object of options to fetch() for POST
    const fetchPro = fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', //telling API -data is json
      },
      body: JSON.stringify(uploadData), //payload
    });
    // Use a timeout to reject the fetch - not hanging out
    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    // await for a API response -will return back the data sent
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message}, (${res.status})`);
    return data;
  } catch (err) {
    // Propagate the error forward to be catch, and reject the promise
    throw err;
  }
};
*/
