// import icons from '../img/icons.svg'; //parcel 1
import icons from 'url:../../img/icons.svg'; //parcel 2

// Used as a common, parent class for all the views
export default class View {
  _data;
  /**
   * Render the received object to the DO
   * @param {Object | Object[]} data The data to be rendered (e.g. recipe)
   * @param {boolen} [render=true] If false, create markup string instead of rendering to the DOM
   * @returns {undefined | string} Amarkup string is returned if render=false
   * @this {Object} View object
   * @author SN
   * @todo Finish implementation
   */
  //public render method, but render parameter is used by other views results and bookmarks with the child -previewView -which need access to this._data; default will be true =to do all code and write to DOM
  render(data, render = true) {
    // check if data is undefined or null or if is an empty array; so if there is no data OR data is an array and empty
    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();
    this._data = data;
    const markup = this._generateMarkup();

    // Return the markup with this._data without inserting html in parent, so previewView will simple render the markup inside its views
    if (!render) return markup;

    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  // Update the DOM only in places where are changes of text & attributes, newElements and curElements should be equal as number
  update(data) {
    // Read the existing DOM
    this._data = data;
    const newMarkup = this._generateMarkup();

    // Convert a string in a real DOM obj, to become a virtual DOM
    const newDOM = document.createRange().createContextualFragment(newMarkup);

    // Select all elements from VIRTUAL DOM and convert them to Array
    const newElements = Array.from(newDOM.querySelectorAll('*'));

    // Select all elements from REAL DOM and covert them to Array
    const curElements = Array.from(this._parentElement.querySelectorAll('*'));

    // Compare the Virtual and Real DOM elements arrays: one by one with isEqualNode()
    newElements.forEach((newEl, i) => {
      const curEl = curElements[i];
      // console.log(curEl, newEl.isEqualNode(curEl));

      // Check for different elements and should be text (the firstChild node of element), otherwise will ruin the html formating
      if (
        !newEl.isEqualNode(curEl) &&
        newEl.firstChild?.nodeValue.trim() !== ''
      ) {
        // console.log('*!*', newEl.firstChild.nodeValue.trim());
        // Write the TEXT content of new elements over curent elements
        curEl.textContent = newEl.textContent;
      }

      // Write the new ATTRIBUTES over old ones, a new if is required
      if (!newEl.isEqualNode(curEl)) {
        // console.log(Array.from(newEl.attributes));
        // Convert to array and loop over and set(write) new attributes
        Array.from(newEl.attributes).forEach(attr =>
          curEl.setAttribute(attr.name, attr.value)
        );
      }
    });
  }

  // create a common method to clear the parent
  _clear() {
    this._parentElement.innerHTML = '';
  }

  // public common method to show a spinner
  renderSpinner = function () {
    const markup = `
      <div class="spinner">
        <svg>
          <use href="${icons}#icon-loader"></use>
        </svg>
      </div> 
    `;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  };

  renderError(message = this._errorMessage) {
    const markup = `
      <div class="error">
        <div>
          <svg>
            <use href="${icons}#icon-alert-triangle"></use>
          </svg>
        </div>
        <p>${message}</p>
      </div>
    `;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  renderMessage(message = this._message) {
    const markup = `
      <div class="message">
        <div>
          <svg>
            <use href="${icons}#icon-smile"></use>
          </svg>
        </div>
        <p>${message}</p>
      </div>
    `;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }
}
