import { async } from 'regenerator-runtime';
import { API_URL, RES_PER_PAGE, KEY } from './config.js';
import { AJAX } from './helpers.js';

// This object will STORE all the app data
export const state = {
  recipe: {},
  search: {
    // query for further data analystics -which querys are made most
    query: '',
    // results returned from search
    results: [],
    // current page of searched results
    page: 1,
    resultsPerPage: RES_PER_PAGE,
  },
  // where the bookmarked recipes will be pushed and later displayed
  bookmarks: [],
};

// A general formater function for data recived
const createRecipeObject = function (data) {
  const { recipe } = data.data; //destructure and redefine terms
  return (state.recipe = {
    id: recipe.id,
    title: recipe.title,
    publisher: recipe.publisher,
    sourceUrl: recipe.source_url,
    image: recipe.image_url,
    servings: recipe.servings,
    cookingTime: recipe.cooking_time,
    ingredients: recipe.ingredients,
    // add the key, but not all of them will have it, && shortcircuits, so if recipe.key is falsy, nothing happen; else the second part of && is executed and returned, then spred it -take the values out of it
    ...(recipe.key && { key: recipe.key }), //key: recipe.key
  });
};

export const loadRecipe = async function (id) {
  try {
    // added the ?(one param) OR &key=${KEY} to add own recipes
    const data = await AJAX(`${API_URL}${id}?key=${KEY}`);
    // const data = await AJAX(`${API_URL}${id}`);

    // Store the data returned and formated in a variable -state
    state.recipe = createRecipeObject(data);

    // Add a new .bookmarked property, which will be set to true for those returned when searched in bookmarks for true condition (.some(condition)); the rest will be false. So when a recipe is clicked if its id will already be in bookmarks array, then its bookmarked status will be true - ex: to show icons.
    if (state.bookmarks.some(bookmark => bookmark.id === id))
      state.recipe.bookmarked = true;
    else state.recipe.bookmarked = false;

    console.log(state.recipe);
  } catch (err) {
    console.error(`${err} *.!.*`);
    throw err;
  }
};

// Controler will pass the query parameter
export const loadSearchResults = async function (query) {
  try {
    state.search.query = query;
    // added the ?(one param) OR &key=${KEY} to add own recipes
    const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`);
    // const data = await AJAX(`${API_URL}?search=${query}`);
    // console.log(data);

    // Create a new array with different objects properties names and store it in state object
    state.search.results = data.data.recipes.map(rec => {
      return {
        id: rec.id,
        title: rec.title,
        publisher: rec.publisher,
        image: rec.image_url,
        // add the key, but not all of them will have it, && shortcircuits, so if recipe.key is falsy, nothing happen; else the second part of && is executed and returned, then spred it -take the values out of it, HERE is for user icon (own recipes)
        ...(rec.key && { key: rec.key }), //key: recipe.key
      };
    });
    // Reset the page number
    state.search.page = 1;
    console.log(state.search.query, state.search.results);
  } catch (err) {
    console.error(`${err} *.!.*`);
    throw err;
  }
};

// Pagination -get only n results
export const getSearchResultsPage = function (page = state.search.page) {
  // on which page we currently are
  state.search.page = page;

  // calculate dinamicaly the page results using the page number
  const start = (page - 1) * state.search.resultsPerPage; // 0;
  const end = page * state.search.resultsPerPage; // 9;
  // slice don't include the last number so 9 will be insted of 10
  return state.search.results.slice(start, end);
};

// Be sure to wait for data to arrive from API
export const updateServings = function (newServings) {
  state.recipe.ingredients.forEach(ing => {
    // multiply orig. quantity with the ratio: new/old
    // newQt = oldQt * newServings / oldServings // 2*8/4 = 4
    ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
  });

  //update the new servings
  state.recipe.servings = newServings;
};

// Using local storage to persist data on refreshes
const persistBookmarks = function () {
  localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks));
};

// Add will use all the data
export const addBookmark = function (recipe) {
  // Add bookmark: push recipe into bookmarks array
  state.bookmarks.push(recipe);

  // Mark current recipe as bookmarked, setting new PROP. on recipe obj.
  if (recipe.id === state.recipe.id) state.recipe.bookmarked = true;

  persistBookmarks();
};

// Delete only use the id
export const deleteBookmark = function (id) {
  // get the index, where id param is bookmarks.id
  const index = state.bookmarks.findIndex(el => el.id === id);
  // delete from index 1 value
  state.bookmarks.splice(index, 1);
  // Mark current recipe as NOT bookmarked, setting PROP. on recipe obj.
  if (id === state.recipe.id) state.recipe.bookmarked = false;

  persistBookmarks();
};

const init = function () {
  //get the bookmarks from local storage
  const storage = localStorage.getItem('bookmarks');
  //if is in the storage, then put it in bookmarks in state obj
  if (storage) state.bookmarks = JSON.parse(storage);
};
init();
// console.log(state.bookmarks);

// Development function to clear the local storage
const clearBookmarks = function () {
  localStorage.clear('bookmarks');
};
// clearBookmarks();

// will transform data (create a similar object to data recived) and connect to API to POST it
export const uploadRecipe = async function (newRecipe) {
  //take ingeredients, convert the obj from param, filter the ingredients keys (entry0) and their values (entry1) to not be empty, clear spaces and put them into a map to return an array
  // console.log(Object.entries(newRecipe));
  try {
    const ingredients = Object.entries(newRecipe)
      .filter(entry => entry[0].startsWith('ingredient') && entry[1] !== '')
      .map(ing => {
        // ing[1] is the second entry, fix the missing spaces in descriptions, split in parts, loop over, trim each elem.
        const ingArr = ing[1].split(',').map(el => el.trim());
        // const ingArr = ing[1].replaceAll(' ', '').split(',');
        // we want the array to have 3 parts, and render message to view and this will reject the promise
        if (ingArr.length !== 3)
          throw new Error(
            'Wrong ingeredient format! Please use the corect format :)'
          );
        const [quantity, unit, description] = ingArr;
        // return an object with ingredients, if there is a quantity return number else null
        return { quantity: quantity ? +quantity : null, unit, description };
      });

    // create the object to be uploaded
    const recipe = {
      title: newRecipe.title,
      source_url: newRecipe.sourceUrl,
      image_url: newRecipe.image,
      publisher: newRecipe.publisher,
      cooking_time: newRecipe.cookingTime,
      servings: +newRecipe.servings,
      ingredients,
    };

    // Create the AJAX request using a unique key and the data
    const data = await AJAX(`${API_URL}?key=${KEY}`, recipe);
    state.recipe = createRecipeObject(data);
    // Create the bookmark for own(new) recipes
    addBookmark(state.recipe);
    // console.log(data);
  } catch (err) {
    throw err;
  }
};
