// A child class view of resultsView.js and bookmarksView.js
import View from './View.js';
import icons from 'url:../../img/icons.svg'; //parcel 2

class PreviewView extends View {
  _parentElement = '';

  // Will generate the markup of one preview element used by other views
  _generateMarkup() {
    // Marking the selected recipe as selected, preview__link--active
    // Set the active class if the result.id is url.#id
    const id = window.location.hash.slice(1);
    return `
      <li class="preview">
        <a class="preview__link ${
          this._data.id === id ? 'preview__link--active' : ''
        }" href="#${this._data.id}">
          <figure class="preview__fig">
            <img src="${this._data.image}" alt="${this._data.image}" />
          </figure>
          <div class="preview__data">
            <h4 class="preview__title">${this._data.title}</h4>
            <p class="preview__publisher">${this._data.publisher}</p>
            <div class="preview__user-generated ${
              this._data.key ? '' : 'hidden'
            }">
              <svg>
              <use href="${icons}#icon-user"></use>
              </svg>
            </div>
          </div>
        </a>
      </li>
    `;
  }
}

export default new PreviewView();
