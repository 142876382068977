import View from './View.js';
import previewView from './previewView.js';
import icons from 'url:../../img/icons.svg'; //parcel 2

class ResultsView extends View {
  _parentElement = document.querySelector('.results');
  _errorMessage = 'No recipes found for your query! Please try again ;)';
  _message = '';

  _generateMarkup() {
    // console.log(this._data);
    return (
      this._data
        // render the child -previewView for each of the bookmarks
        .map(result => previewView.render(result, false))
        .join('')
    );
  }
}

export default new ResultsView();
