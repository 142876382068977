import View from './View.js';
import icons from 'url:../../img/icons.svg'; //parcel 2

class PaginationView extends View {
  _parentElement = document.querySelector('.pagination');

  // Publisher-subscriber pattern -cbfunc,handler, will be the control fn
  addHandlerClick(handler) {
    //use event delegation - 2 btns; add listener to the parent element
    this._parentElement.addEventListener('click', function (e) {
      e.preventDefault();

      //we can't call imediately the handler bcs we need to know which btn was clicked, we use .closest() to search up in the tree
      const btn = e.target.closest('.btn--inline');
      if (!btn) return;

      //read the dataset htmp prop.,convert to number + and pass to handler
      const goToPage = +btn.dataset.goto;
      handler(goToPage);
    });
  }

  //_data from View.js is now the entire state.search object
  _generateMarkup() {
    const curPage = this._data.page;
    //how many pages there are? integers
    const numPages = Math.ceil(
      this._data.results.length / this._data.resultsPerPage
    );

    //Page 1, and there are other pages
    if (curPage === 1 && numPages > 1) {
      return this._generateMarkupButton(curPage, 'n');
      // `
      //   <button class="btn--inline pagination__btn--next">
      //     <span>Page ${curPage + 1}</span>
      //     <svg class="search__icon">
      //       <use href="${icons}#icon-arrow-right"></use>
      //     </svg>
      //   </button>
      // `;
    }

    //Last page
    if (curPage === numPages && numPages > 1) {
      return this._generateMarkupButton(curPage, 'p');
      // `
      //   <button class="btn--inline pagination__btn--prev">
      //     <svg class="search__icon">
      //       <use href="${icons}#icon-arrow-left"></use>
      //     </svg>
      //     <span>Page ${curPage - 1}</span>
      //   </button>
      // `;
    }

    //Other page
    if (curPage < numPages) {
      const prev = this._generateMarkupButton(curPage, 'p');
      const next = this._generateMarkupButton(curPage, 'n');
      return prev + next;
      // `
      //   <button class="btn--inline pagination__btn--prev">
      //     <svg class="search__icon">
      //       <use href="${icons}#icon-arrow-left"></use>
      //     </svg>
      //     <span>Page ${curPage - 1}</span>
      //   </button>
      //   <button class="btn--inline pagination__btn--next">
      //     <span>Page ${curPage + 1}</span>
      //     <svg class="search__icon">
      //       <use href="${icons}#icon-arrow-right"></use>
      //     </svg>
      //   </button>
      // `;
    }

    //Page 1, and there are NO other pages
    return '';
  }

  // refactor the repeated html buttons
  // default NEXT btn, else if param diferent than '' is PREV btn
  // added data-goto to store the page number
  _generateMarkupButton(page, sense) {
    return `
      <button data-goto="${
        sense === 'p' ? page - 1 : page + 1
      }" class="btn--inline pagination__btn--${
      sense === 'p' ? 'prev' : 'next'
    }">
        <span>Page ${sense === 'p' ? page - 1 : page + 1}</span>
        <svg class="search__icon">
          <use href="${icons}#icon-arrow-${
      sense === 'p' ? 'left' : 'right'
    }"></use>
        </svg>
      </button>
    `;
  }
}

export default new PaginationView();
